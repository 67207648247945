
import { ICountry } from '@/store/modules/general/types';
import { ComposedState } from '@/types';

import rest from '@/api/index';

import Vue, { PropType } from 'vue';
import { mapState } from 'vuex';

export default Vue.extend({
    name: 'NewLang',

    props: {
        alreadyLangs: {
            type: Array as PropType<String[]>,
            required: true,
        },
    },

    data() {
        return {
            newFile: null as File | null,
            selectedLang: null as null | string,
            errorMessage: null as null | string,
            successMessage: null as null | string,
        };
    },

    computed: {
        ...mapState<ComposedState>({
            countries: (state: ComposedState) => state.general.countries,
        }),

        actualCountries(): ICountry[] {
            // @ts-ignore
            return this.countries
                .filter((country: ICountry) => !this.alreadyLangs.includes(country.code))
                .map((country: ICountry) => country.code);
        },
    },

    methods: {
        onChangeFile(file: File) {
            if (!file) {
                return this.newFile = null;
            }

            this.newFile = file;
        },

        async addLang() {
            if (!this.newFile || !this.selectedLang) return;

            const formData = new FormData();
            formData.append('file', this.newFile, this.newFile.name);
            formData.append('lang', String(this.selectedLang).toLowerCase());

            await rest.uploadVocabulary(formData)
                .then(() => {
                    this.successMessage = 'OK';
                    this.$emit('update-langs');
                })
                .catch((e) => {
                    const { error } = e.response.data.detail;

                    this.errorMessage = error || 'Error';                    
                });
        },
    },
});
