

// @ts-nocheck

import Vue from 'vue';

import NewLang from './NewLang.vue';
import { mapActions, mapState } from 'vuex';
import { generalActions } from '@/store/modules/general/names';

import rest from '@/api/index';

import { ComposedState } from '@/types';

interface ILangForTable {
    lang: string;
    download: boolean;
    upload: boolean;
    error: string;
}

interface INewFileConfig {
    lang: string;
    file: FormData;
}

export default Vue.extend({
    name: 'LangManager',

    components: { NewLang },

    data() {
        return {
            headers: [
                {
                    text: 'Languages',
                    value: 'lang',
                    sortable: false,
                },
                {
                    text: 'Get the latest version',
                    value: 'download',
                    sortable: false,
                },
                {
                    text: 'Upload the updated version of the language file',
                    value: 'upload',
                    sortable: false,
                },
                {
                    text: 'Server response',
                    value: 'error',
                    sortable: false,
                },
            ],
            langList: null as String[] | null,
            loading: false,
            newFileConfig: null as null | INewFileConfig,
            errorMessage: null,
            successMessage: null,
            formData: null as FormData | null,
        };
    },

    computed: {
        langArr(): ILangForTable[] {
            return this.langList?.map((lang: any) => ({
                lang,
                download: true,
                upload: true,
                error: '',
            }));
        },

        ...mapState<ComposedState>({
            token: (state: ComposedState) => state.admin.access,
        }),
    },

    async mounted() {
        await this.getCountries();
        await this.getLangs();
    },

    methods: {
        ...mapActions({
            getCountries: generalActions.getCountries,
        }),

        async downloadFile(lang: string) {
            const link = `${process.env.VUE_APP_URL_REST}/admin/language/download/${lang}`;
            const headers = {
                Authorization: `JWT ${this.token}`,
            };

            try {
                const response = await fetch(link, { headers });
                const blob = await response.blob();
                const a = document.createElement('a');

                a.href = URL.createObjectURL(blob);
                a.download = `${lang}.xlsx`;
                a.click();
                a.remove();

                URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error on download file:', error);
            }
        },

        getFlag(lang: string) {
            return `flag-${lang?.toUpperCase()}`;
        },

        onChangeFile(file: File, lang: string) {
            if (!file) {
                return this.newFileConfig = null;
            }

            this.newFileConfig = { lang, file };

            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('lang', String(lang).toLowerCase());
            
            this.formData = formData;
        },

        async uploadFile() {
            if (!this.newFileConfig || !this.formData) return;

            await rest.uploadVocabulary(this.formData)
                .then(async () => {
                    this.successMessage = 'OK';
                    await this.getLangs();
                })
                .catch((e) => {
                    const { error } = e.response.data.detail;

                    this.errorMessage = error || 'Error';        
                });
        },

        async getLangs() {
            this.loading = true;
            await rest.getLanguages()
                .then((res) => {
                    this.langList = Object.keys(res.data); 
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
});
